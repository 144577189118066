import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getRandomInt } from "../../Components/Shared/Functions/Functions";
import IngredientComponent from "./IngredientComponent";
import { recipeContext } from "../../contexts/RecipeContext";
import { useSelector } from "react-redux";
import Select from "react-select";

const EditRecipe = ({ isNewRecipe }) => {
  const { register, handleSubmit } = useForm();
  const user = useSelector((state) => state.user.data);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    Recipe,
    SetRecipe,
    emptyIngredient,
    options,
    unitOptions,
    mealType,
    SetMealType,
  } = useContext(recipeContext);

  const [getTypeIndex, SetTypeIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // console.log(isNewRecipe);
    if (isNewRecipe === false) {
      fetch(`${process.env.REACT_APP_SERVER_URL}/recipe/${id}`)
        .then((res) => res.json())
        .then((data) => {
          delete data[0]._id;

          let getTypeIndex = mealType.findIndex(
            (type) => type.value === data[0].mealType
          );
          SetTypeIndex(getTypeIndex);
          SetRecipe(data[0]);
          setLoaded(true);
        });
    } else {
      SetRecipe({
        name: "name",
        Ingredient: [emptyIngredient],
        carbonEmission: 0,
      });
      setLoaded(true);
    }
  }, []);

  const dynamicColorButton = (color) => {
    return `py-2 px-4 flex bg-${color}-600 hover:bg-${color}-700 focus:ring-${color}-500 focus:ring-offset-${color}-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg `;
  };

  const inputClass =
    "rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparenta";

  const onDelete = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/recipe/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        navigate("/", { replace: true });
      });
  };

  const onSubmit = () => {
    // SetRecipe({ name: Recipe.name, Ingredient });
    // console.log({ ...Recipe, edititem: true });

    // console.log(Recipe);
    let newRecipe = { ...Recipe };
    // console.log(user);
    newRecipe.userId = user.userId;

    // console.log(newRecipe);

    SetRecipe({ ...Recipe });

    let url;
    if (isNewRecipe === true) {
      url = `${process.env.REACT_APP_SERVER_URL}/recipe`;
      // console.log(url);
    } else if (isNewRecipe === false) {
      url = `${process.env.REACT_APP_SERVER_URL}/recipe/${id}`;
    }

    // console.log(url);
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ ...newRecipe }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        navigate("/meals");
      });
  };

  const onAddItem = () => {
    // console.log({ ...Recipe, edititem: true });
  };

  const onDeleteItem = () => {
    // console.log({ ...Recipe, edititem: true });
    const url = `${process.env.REACT_APP_SERVER_URL}/recipe/${id}`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ ...Recipe }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        // navigate(from);
      });
  };

  const handleTypeChange = (e, key) => {
    Recipe[key] = e.value;

    let getTypeIndex = mealType.findIndex(
      (type) => type.value === Recipe.mealType
    );
    SetTypeIndex(getTypeIndex);
    SetRecipe({ ...Recipe });
  };

  return (
    loaded && (
      <div className="mt-[50px] container mx-auto">
        <div className="relative mx-auto w-[70vw] p-16 border-blue-300 border-2">
          <h1 className="text-4xl font-extrabold pb-10">
            {isNewRecipe === true ? "Create" : "Edit"} a Meal
          </h1>
          <form className="flex flex-col " onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-3 gap-6 w-3/5">
              <div>
                <label
                  htmlFor="name-with-label"
                  className="text-gray-700 me-4 font-bold"
                >
                  Meal Name
                </label>
                <input
                  type="text"
                  className={`form-control ${inputClass}`}
                  aria-describedby="basic-addon3"
                  value={Recipe.name}
                  onChange={(e) =>
                    SetRecipe({ ...Recipe, name: e.target.value })
                  }
                ></input>
              </div>
              <div>
                <label
                  htmlFor="name-with-label"
                  className="text-gray-700 me-4 font-bold"
                >
                  Meal Type
                </label>
                <Select
                  value={mealType[getTypeIndex]}
                  options={mealType}
                  onChange={(e) => handleTypeChange(e, "mealType")}
                ></Select>
              </div>
              <div>
                <label
                  htmlFor="name-with-label"
                  className="text-gray-700 me-4 font-bold"
                >
                  Number of Portions
                </label>
                <input
                  type="text"
                  className={`form-control ${inputClass}`}
                  aria-describedby="basic-addon3"
                  value={Recipe.servings}
                  onChange={(e) =>
                    SetRecipe({ ...Recipe, servings: e.target.value })
                  }
                ></input>
              </div>
            </div>

            <p className="font-bold py-3">Ingredients:</p>
            
            { Recipe.Ingredient.length > 0 ? 
            <>
              <div className="grid grid-cols-7 gap-4">
                <div className="col-span-2">
                  <label
                    htmlFor="name-with-label"
                    className="text-gray-700 me-4 font-bold"
                  >
                    Name
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="name-with-label"
                    className="text-gray-700 me-4 font-bold"
                  >
                    Quantity
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="name-with-label"
                    className="text-gray-700 me-4 font-bold"
                  >
                    Unit
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="name-with-label"
                    className="text-gray-700 me-4 font-bold"
                  >
                    CF Kg CO<sub>2</sub>
                  </label>
                </div>
              </div>
            </>
            : ''}

            <div className="grid grid-cols-7 gap-4">
              {/* {console.log(Recipe)} */}
              {Recipe.Ingredient.map((item, index) => {
                let getOptionsIndex = options.findIndex(
                  (option) => option.value === item.name.name
                );
                let getUnitOptionsIndex = unitOptions.findIndex(
                  (option) => option.value === item.unit
                );
                const cmpProps = {
                  item,
                  inputClass,
                  index,
                  dynamicColorButton,
                  emptyIngredient,
                  isNewRecipe,
                  getOptionsIndex,
                  getUnitOptionsIndex,
                };
                return (
                  <>
                    <IngredientComponent key={index} {...cmpProps} />
                  </>
                );
              })}
            </div>

            <br />
            {/* <div  className="flex flex-row ms-auto"> */}
            <div className="grid grid-cols-7 gap-4">
              <div className="col-span-2 flex items-center">
                <button
                  href="#!"
                  // className={`${dynamicColorButton("indigo")} mr-3`}
                  className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-3`}
                  type="submit"
                  value="Add Product"
                >
                  Submit
                </button>
                <button
                  href="#!"
                  type="button"
                  // className={`${dynamicColorButton("red")} mr-3`}
                  className={`bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded mr-3`}
                  onClick={() => onDelete()}
                  value="Add Product"
                >
                  Delete
                </button>
              </div>
              <div className="col-span-2"></div>
              <div>
                <label
                  htmlFor="name-with-label"
                  className="text-gray-700 me-4 font-bold"
                >
                  Kg CO<sub>2</sub> Total
                </label>
                <input
                  type="text"
                  disabled
                  className={`form-control ${inputClass} bg-gray-50`}
                  value={Recipe.carbonEmission}
                ></input>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default EditRecipe;
