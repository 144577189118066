import React from "react";
import logo from "../../../assets/becarbon-logo.png";
import facebook from "../../../assets/facebook.svg";
import linkedin from "../../../assets/linkedin.svg";
import github from "../../../assets/github.svg";
import bgImage from "../../../assets/bg-image.svg";

const Footer = () => {
  return (
    <footer
      className="border-t-2 mt-10"
      // style={{ backgroundImage: `url(${bgImage})` }}
    >
      {/* <div className="grid grid-cols-4 mt-10 mx-auto gap-24 text-xl mx-auto container"> */}
      <div className="mt-10 text-xl mx-auto container">
        <div className="flex justify-center">
          <div>
          <img className="h-16" src={logo} alt="" />
          {/* <p>.................@gmail.com</p> */}
          <div className="flex justify-center items-center gap-5 mt-5">
            <img className="h-10" src={facebook} alt="" />
            <img className="h-10" src={linkedin} alt="" />
            <img className="h-10" src={github} alt="" />
          </div>

          </div>
        </div>
        <div>
          {/* <h2 className=" font-bold ">PRODUCT</h2>
          <a href="/">Solution</a> <br />
          <a href="/">Trendsetters</a> <br />
          <a href="/">Pricing</a> <br /> */}
        </div>
        <div>
          {/* <h2 className=" font-bold ">COMPANY</h2>
          <a href="/">About ...</a> <br />
          <a href="/">Contact us</a> <br />
          <a href="/">Work at ...</a> <br /> */}
        </div>
        <div>
          {/* <h2 className=" font-bold ">FOOD FOR THOUGHT</h2>
          <a href="/">About ...</a> <br />
          <a href="/">Contact us</a> <br />
          <a href="/">Work at ...</a> <br /> */}
        </div>
      </div>
      <div className="mx-20 mt-10"></div>
      {/* <div className="flex items-center justify-between mx-20 text-xl mx-auto container">
        <div>
          <a href="/">Legal information</a>
          <a className="ml-10" href="/">
            Privacy
          </a>
          <a className="ml-10" href="/">
            Cookie
          </a>
        </div>
        <p>© ....... 2022, All Rights Reserved</p>
      </div> */}
    </footer>

  );
};

export default Footer;
