import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getRandomInt } from "../../Components/Shared/Functions/Functions";
import { recipeContext } from "../../contexts/RecipeContext";
import useRecipes from "../../hooks/useRecipe";
import PageTitle from "../Shared/PageTitle/PageTitle";

const ListRecipe = () => {
  const { role, userId } = useSelector((state) => state.user.data);
  // const [recipes, setRecipes] = useRecipes();
  const [recipes, setRecipes] = useState();
  const location = useLocation();
  const { id } = useParams();

  const { mealType } = useContext(recipeContext);

  const dynamicColorButton = (color) => {
    return `py-2 px-4 bg-${color}-600 hover:bg-${color}-700 focus:ring-${color}-500 focus:ring-offset-${color}-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg`;
  };

  useEffect(() => {
    let URL;
    console.log(`/dashboard/recipes/${id}`);

    switch (role) {
      case "admin":
        switch (location.pathname) {
          case "/recipes":
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
          case "/dashboard/recipes":
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
          case `/dashboard/recipes/${id}`:
            URL = `${process.env.REACT_APP_SERVER_URL}/user/${id}/recipe`;
            break;
          default:
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
        }
        break;
      case "general":
        URL = `${process.env.REACT_APP_SERVER_URL}/user/${userId}/recipe`;
        break;
      default:
        URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
    }
    fetch(URL)
      .then((res) => res.json())
      .then((data) => setRecipes(data));
  }, []);

  const deleteItem = (id) => {
    const proceed = window.confirm("Are you sure?");
    if (proceed) {
      const url = `${process.env.REACT_APP_SERVER_URL}/recipe/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          const remaining = recipes.filter((recipe) => recipe._id !== id);
          setRecipes(remaining);
        });
    }
  };
  return (
    <div>
      <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
        {mealType.map((type) => {
          return (
            <>
              <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                  <h2 className="text-2xl leading-tight">{type.value}</h2>
                  {/* <div className="text-end">
                    <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                      <div className="relative ">
                        <input
                          type="text"
                          id='"form-subscribe-Filter'
                          className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          placeholder="name"
                        />
                      </div>
                      <button
                        className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                        type="submit"
                      >
                        Filter
                      </button>
                    </form>
                  </div> */}
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                  <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                          >
                            CE
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {recipes
                          ?.filter((recipe) => recipe.mealType === type.value)
                          .map((recipe, i) => {
                            let carbonArray = recipe?.Ingredient?.map((item) =>
                              parseFloat(item.carbonEmission)
                            );
                            const sum = carbonArray.reduce(
                              (partialSum, a) => partialSum + a,
                              0
                            );
                            return (
                              <>
                                <tr key={i}>
                                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div className="flex items-center">
                                      <div>
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {recipe.name}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div className="flex items-center">
                                      <div>
                                        <p className="text-gray-900 whitespace-no-wrap font-bold">
                                          {sum.toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <Link
                                      to={`/recipes/edit/${recipe._id}`}
                                      className={`${dynamicColorButton(
                                        "indigo"
                                      )} mr-3`}
                                      type="button"
                                    >
                                      Edit
                                    </Link>

                                    <button
                                      href="#!"
                                      type="button"
                                      className={`${dynamicColorButton("red")}`}
                                      onClick={() => deleteItem(recipe._id)}
                                      value="Add Product"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}

                        {/* <tr>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center">
                              <div>
                                <p className="text-gray-900 whitespace-no-wrap"></p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center">
                              <div>
                                <p className="text-gray-900 whitespace-no-wrap"></p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <Link
                              to={`/recipes/add`}
                              className={`${dynamicColorButton("indigo")} mr-3`}
                              type="button"
                            >
                              Add New Recipe
                            </Link>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    {/* <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                      <div className="flex items-center">
                        <button
                          type="button"
                          className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                        >
                          <svg
                            width="9"
                            fill="currentColor"
                            height="8"
                            className=""
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"></path>
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 "
                        >
                          1
                        </button>
                        <button
                          type="button"
                          className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                        >
                          <svg
                            width="9"
                            fill="currentColor"
                            height="8"
                            className=""
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                          </svg>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ListRecipe;
