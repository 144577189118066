import React from "react";
import Footer from "../../Components/Shared/Footer/Footer";
import Navbar from "../../Components/Shared/Navbar/Navbar";
import Banner from "./Banner/Banner";
import BannerImg from "./Banner/BannerImg";
import Description from "./Description";
import Importance from "./Importance";

const Home = () => {
  return (
    <div className="mt-20">
      <Banner></Banner>
      <Description/>
      <Importance/>

      {/* <BannerImg></BannerImg> */}
      <Footer />
    </div>
  );
};

export default Home;
