import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";

const Dashboard = () => {
  return (
    <>
      <main class="bg-gray-100 dark:bg-gray-800 rounded-2xl overflow-auto relative">
        <div class="flex items-start justify-between">
          <div class="h-screen hidden lg:block my-4 ml-4 shadow-lg relative w-80">
            <SideBar/>
          </div>
          <div class="w-full pl-0 md:p-4 md:space-y-4 h-full">
            <Outlet/>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
