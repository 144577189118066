import { signOut } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/becarbon-logo.png";
import EN from "../../../assets/logo.webp";
import auth from "../../../firebase.init";
import CustomLink from "../../../Pages/Shared/CustomLink/CustomLink";
import { logoutUser } from "../../../Redux/Features/User/userSlice";

const Navbar = () => {
  const navigate = useNavigate();
  // const [user] = useAuthState(auth);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  // console.log(user);
  // user && console.log(user.uid);
  const handleSignOut = () => {
    signOut(auth);
    dispatch(logoutUser());
    navigate('/')
  };

  return (
    <>
      <div className="flex items-center justify-between max-w-[1024px] mx-auto">
        <Link to="/">
          <img className="h-12 m-3 " src={logo} alt="" />
        </Link>
        <div className="lg:block hidden">
          <ul className="menu menu-horizontal flex gap-5">
            {/* <li>About</li>
            <li>Solution</li>z
            <li>Trendsetters</li>
            <li>Contact</li> */}
            <CustomLink className="nav-link px-2" to="/">
              Home-Page
            </CustomLink>
            <CustomLink className="nav-link px-2" to="/about">
              About
            </CustomLink>
  
            
            {user ? (
            <>
            {/* <CustomLink className="nav-link px-2" to="/menu">
              Menu
            </CustomLink> */}
            <CustomLink className="nav-link px-2" to="/meals">
              Meals
            </CustomLink>
            
            { user.role ==='admin' ?
              <CustomLink className="nav-link px-2" to="/dashboard">
                Dashboard
              </CustomLink>
            :''}
            
            </>        
            ):<></>}
  
          </ul>
        </div>
        <div className="lg:flex items-center  hidden ">
          <div className="flex items-center gap-2 ">
            EN
            <img className="w-6 h-5" src={EN} alt="" />
          </div>
          <div className="border mx-4 h-10 bg-gray-600"></div>
          
          {!user ? (
            <>
            <div>
            <Link to="/login" className="" href="/">
              Login
            </Link>
            <Link to="/register">
              <button className="bg-[#60c385] text-white font-normal ml-3 p-3  rounded">
                Get Started
              </button>
            </Link>
          </div>
          </>
          ):
          <button onClick={handleSignOut} className="bg-[#60c385] text-white font-normal text-xl ml-3 p-3  rounded">
            Logout
          </button>
          }
          
  
        </div>
        <div className="lg:hidden cursor-pointer">
          <img src="" alt="" />
        </div>
      </div>
      <hr/>
    </>
  );
};

export default Navbar;
