import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [userList, setUserList] = useState();
  useEffect(() => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/user`;
    fetch(URL)
      .then((res) => res.json())
      .then((data) => setUserList(data));
  }, []);

  const user = useSelector(state => state.user.data)

  const authorization = (currentUser) => {
    switch (true) {
      case user.role === 'admin':
        return false;
        break; 
      case user.role ==='general' && currentUser._id === user.userId:
        return false ;
      default:
        return true;
        break;
    }
  }

  const dynamicColorButton = (color) => {
    return `py-2 px-4 flex bg-${color}-600 hover:bg-${color}-700 focus:ring-${color}-500 focus:ring-offset-${color}-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg `;
  };

  const navigate = useNavigate();

  const makeAdmin = async (id) => {
    let URL = `${process.env.REACT_APP_SERVER_URL}/user/makeAdmin`;
    fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
    .then(res => res.json())
    .then(data => {
      // console.log(data)
      window.location.reload(false);
    })
  };

  const makeGeneral = async (id) => {
    let URL = `${process.env.REACT_APP_SERVER_URL}/user/makeGeneral`;
    fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
    .then(res => res.json())
    .then(data => {
      // console.log(data)
      window.location.reload(false);
    })
  };

  const deleteUser = async (id) => {
    let URL = `${process.env.REACT_APP_SERVER_URL}/user/delete`;
    fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
    .then(res => res.json())
    .then(data => {
      // console.log(data)
      window.location.reload(false);
    })
  };

  return (
    <div class="container mx-auto px-4 sm:px-8 max-w-3xl">
      <div class="py-8">
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
          <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >                    
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  ></th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  ></th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {userList?.map((user) => {
                  // console.log(user);
                  return (
                    <tr>
                      <>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <a href="#" class="block relative">
                                <img
                                  alt="profil"
                                  src={require("../../assets/Facebook-logo.png")}
                                  class="mx-auto object-cover rounded-full h-10 w-10 "
                                />
                              </a>
                            </div>
                            <div class="ml-3">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {user.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            
                          </p>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {user.role}
                          </p>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <button
                            disabled={authorization(user)}
                            type="button"
                            className={`${dynamicColorButton("indigo")} mr-3 disabled:cursor-not-allowed`}
                            onClick={() =>
                              navigate(`/dashboard/recipes/${user._id}`)
                            }
                            value="Add Product"
                          >
                            Meals
                          </button>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <button
                            type="button"
                            className={`${dynamicColorButton("red")} mr-3`}
                            onClick={() => 
                              {
                                user.role === 'admin' ?
                                makeGeneral(user._id) :
                                makeAdmin(user._id)
                              }
                            }
                            value="Add Product"
                          >
                            {user.role === 'admin' ? 'General' : 'Admin'}
                          </button>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <button
                            type="button"
                            className={`${dynamicColorButton("red")} mr-3`}
                            onClick={() => deleteUser(user._id)}
                            value="Add Product"
                          >
                            Delete
                          </button>
                        </td>
                        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <button
                            type="button"
                            className={`${dynamicColorButton("indigo")} mr-3`}
                            onClick={() => navigate("/recipes")}
                            value="Add Product"
                          >
                            Edit
                          </button>
                        </td>
                      </>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div class="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
              <div class="flex items-center">
                <button
                  type="button"
                  class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 "
                >
                  1
                </button>
                <button
                  type="button"
                  class="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
