import React, { useContext, useEffect, useState } from "react";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import { getRandomInt } from "../../Components/Shared/Functions/Functions";
import { recipeContext } from "../../contexts/RecipeContext";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";

const IngredientComponent = ({
  item,
  inputClass,
  index,
  dynamicColorButton,
  isNewRecipe,
  getOptionsIndex,
  getUnitOptionsIndex,
}) => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { Recipe, SetRecipe } = useContext(recipeContext);
  const { options, setOptions, unitOptions, setUnitOptions, emptyIngredient } =
    useContext(recipeContext);
  
  const [ingredient, SetIngredient] = useState();

  useEffect(()=>{
    SetIngredient({...ingredient, name: options[getOptionsIndex], unit:unitOptions[getUnitOptionsIndex]})
  },[getOptionsIndex, getUnitOptionsIndex])

  // console.log(options, unitOptions);

  // const [options, setOptions] = useState([{ value: 'ocean', label: 'Ocean'},{ value: 'purple', label: 'Purple' }])

  const handleNameChange = (e, key) => {
    item[key] = { name: e.value, _id: e._id };
    SetRecipe({ ...Recipe });
  };

  const handleInputchange = (e) => {
    // return console.log(e);
    // const url = `${process.env.REACT_APP_SERVER_URL}/carbonfootprint`
    // fetch(url,{
    //   method: 'GET'
    // })
    //   .then((res)=> res.json())
    //   .then((data)=>{
    //     console.log(data)
    //     let map = data.map(item => {
    //       return { value: item.FC_ITEM, label: item.FC_ITEM, _id: item._id}
    //     })
    //     console.log(map);
    //     setOptions(map);
    //   })
    // return ;
    // return console.log(e);
  };

  // useEffect(() => {
  //   const url = `${process.env.REACT_APP_SERVER_URL}/carbonfootprint`
  //   fetch(url,{
  //     method: 'GET'
  //   })
  //     .then((res)=> res.json())
  //     .then((data)=>{
  //       console.log(data)
  //       let map = data.map(item => {
  //         return { value: item.FC_ITEM, label: item.FC_ITEM, _id: item._id}
  //       })
  //       console.log(map);
  //       setOptions(map);
  //     })
  // }, [])

  const setUnitFiled = (e, key) => {
    item[key] = e.value;
    SetRecipe({ ...Recipe });
  };
  const setInputFiled = (e, key) => {
    item[key] = e.target.value;
    SetRecipe({ ...Recipe });
  };

  useEffect(() => {
    if (parseFloat(options[getOptionsIndex]?.emission) > 0) {
      let unitMultiplier;
      switch (item.unit) {
        case "litre":
          unitMultiplier = 1;
          break;
        case "kg":
          unitMultiplier = 1;
          break;
        case "millilitre":
          unitMultiplier = 0.001;
          break;
        case "gram":
          unitMultiplier = 0.001;
          break;
        default:
          unitMultiplier = 0;
          break;
      }
      let emission;

      emission =
        parseFloat(options[getOptionsIndex]?.emission) *
        parseFloat(item.quantity) *
        unitMultiplier;
      let newRecipe = { ...Recipe };
      newRecipe.Ingredient[index].carbonEmission = emission.toFixed(2);

      let carbonArray = newRecipe?.Ingredient?.map((item) =>
        parseFloat(item.carbonEmission)
      );
      const sum = carbonArray.reduce((partialSum, a) => partialSum + a, 0);
      newRecipe.carbonEmission=sum.toFixed(2);

      SetRecipe({ ...newRecipe });
    }
  }, [item.name, item.quantity, item.unit]);
  return (
    <>
      {/* {((getOptionsIndex>-1 && getUnitOptionsIndex>-1)||((item.name===''||item.unit==='')&& isNewRecipe)) && */}
      {
        <>
          <div className="col-span-2">
            
            {/* <input
            type="text"
            className={`form-control ${inputClass}`}
            aria-describedby="basic-addon3"
            value={item.name}
            onChange={(e) => setInputFiled(e, "name")}
          ></input> */}
            <Select
              // defaultValue={options[getOptionsIndex]}
              value={ingredient?.name}
              isLoading={isLoading}
              // isClearable={isClearable}
              options={options}
              onChange={(e) => handleNameChange(e, "name")}
              // onInputChange = {(e)=>handleInputchange(e)}
              // className={`form-control ${inputClass}`}
              // aria-describedby="basic-addon3"
              // value={item.name}
              // onChange={(e) => setInputFiled(e, "name")}
            ></Select>
          </div>

          <div>
            
            <input
              type="text"
              className={`form-control ${inputClass}`}
              aria-describedby="basic-addon3"
              value={item.quantity}
              onChange={(e) => setInputFiled(e, "quantity")}
            ></input>
          </div>

          <div>
            {/* <input
            type="text"
            className={`form-control ${inputClass}`}
            aria-describedby="basic-addon3"
            value={item.unit}
            onChange={(e) => setInputFiled(e, "unit")}
          ></input> */}

            {/* <AutoComplete
            options={["Chennai", "Mumbai", "Bangalore"]}
            value={selectedOption}
            onChange={setSelectedOption}
          /> */}

            <Select
              // defaultValue={unitOptions[0]}
              // defaultValue={unitOptions[getUnitOptionsIndex]}
              // defaultValue={ingredient?.unit}
              value={ingredient?.unit}
              isLoading={isLoading}
              // isClearable={isClearable}
              name="unit"
              options={unitOptions}
              // onChange={(e)=>handlechange(e)}
              onChange={(e) => setUnitFiled(e, "unit")}
              // onChange={(e) => setUnitFiled(e, "unit")}
              // onInputChange = {(e)=>handleInputchange(e)}
            />
          </div>

          <div>
            <input
              type="text"
              disabled
              className={`form-control ${inputClass} bg-gray-50`}
              value={item.carbonEmission}
            ></input>
          </div>
        </>
      }

      <div className="flex items-center">
        <button
          type="button"
          // className={`${dynamicColorButton("indigo")} mx-auto`}
          className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-3 mx-auto`}
          onClick={(e) => {
            //inserting a new ingredient between two other ingredients. Pushing item at specific index of an array
            let newIngredient = [...Recipe.Ingredient];
            newIngredient.splice(index + 1, 0, emptyIngredient).join();
            newIngredient.join();
            SetRecipe({ ...Recipe, Ingredient: [...newIngredient] });
          }}
        >
          Add
        </button>
      </div>
      <div className="flex items-center">
        <button
          type="button"
          // className={`${dynamicColorButton("red")} mx-auto`}
          className={`bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded mx-auto`}
          onClick={(e) => {
            let a = [...Recipe.Ingredient];
            a.splice(index, 1);
            SetRecipe({ ...Recipe, Ingredient: [...a] });
          }}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default IngredientComponent;
