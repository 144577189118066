import React from "react";

const Description = () => {
  return (
    <div className="grid grid-cols-3 py-20 max-w-[1024px] mx-auto">
      <div>
        <h1 className="text-4xl">Be<span className="font-semibold">Carbon</span>Smart</h1>
      </div>
      <div className="col-span-2 [&>p]:mb-5 text-lg  font-[350]">
        <p>
          This website provides a free tool for chefs and individuals to design and develop recipes for meals that provide the amount of carbon emissions associated with individual meals.
        </p>
  
        <p>
          Explore what types of food have a higher carbon contribution, choose and replace with low impact ingredients.
        </p>
  
        <p>
          Design starter, main, dessert and side menus. Inform your customers and help them make th right decision for the planet
        </p>
      </div>
    </div>
  );
};

export default Description;
