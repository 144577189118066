import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Shared/Navbar/Navbar";
import PageTitle from "./Components/Shared/PageTitle/PageTitle";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login/Login";
import Register from "./Pages/Login/Register/Register";
import RequireAuth from "./Pages/Login/RequireAuth/RequireAuth";
import Menu from "./Pages/Menu/Menu";
import EditRecipe from "./Pages/Recipe/EditRecipe";
import ListRecipe from "./Pages/Recipe/ListRecipe";
import { recipeContext } from "./contexts/RecipeContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Users from "./Components/Users/Users";
import ListMeals from "./Pages/Recipe/ListMeals";
import About from "./Pages/About/About";

function App() {
  const [options, setOptions] = useState([{ value: '', label: ''}])
  
  const [unitOptions, setUnitOptions] = useState([
    { value: 'kg', label: 'kg'},
    { value: 'gram', label: 'gram' },
    { value: 'litre', label: 'litre' },
    { value: 'millilitre', label: 'millilitre' }  
  ])

  const [mealType, SetMealType] = useState([
    { value: 'Starter', label: 'Starter'},
    { value: 'Main Course', label: 'Main Course' },
    { value: 'Dessert', label: 'Dessert' },
    { value: 'Side Dish', label: 'Side Dish' }  
  ])

  const emptyIngredient = {
    name: "",
    quantity: "",
    unit: "",
    carbonEmission:0
  };

  const [Recipe, SetRecipe] = useState({
    name: "name",
    Ingredient: [emptyIngredient],
    mealType:'',
    servings:0,
    carbonEmission:0
  });

  useEffect(() => {
    const url = `${process.env.REACT_APP_SERVER_URL}/carbonfootprint`
    fetch(url,{
      method: 'GET'
    })
      .then((res)=> res.json())
      .then((data)=>{
        // console.log(data)
        let map = data.map(item => {
          return { 
            // value: item.FC_ITEM, 
            // label: item.FC_ITEM, 
            value: item.Ingredient, 
            label: item.Ingredient, 
            _id: item._id,
            emission: item.emission
          }
        })
        // console.log(map);
        setOptions(map);
      })
  }, []);

  
  return (
    <>
      <recipeContext.Provider value={{
            options, 
            setOptions,
            unitOptions,
            setUnitOptions,
            mealType,
            SetMealType,
            Recipe,
            SetRecipe,
            emptyIngredient
          }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/menu" element={<Menu />}></Route>
        <Route path="/recipes" element={<ListRecipe/>}></Route>  
        <Route path='/meals' element={<ListMeals/>}></Route>      
        <Route path="/recipes/add" element={<EditRecipe isNewRecipe={true} />}></Route>        
        <Route path="/recipes/edit/:id" element={<EditRecipe isNewRecipe={false} />}></Route>
        <Route path="/dashboard" element={<Dashboard/>}>
          <Route index element={<Users/>}></Route>
          {/* <Route path='recipes' element={<ListRecipe/>}></Route> */}
          <Route path='meals' element={<ListMeals/>}></Route>
        <Route path='recipes/:id' element={<ListMeals/>}></Route>
        </Route>
      </Routes>
      </recipeContext.Provider>
    </>
  );
}

export default App;
