import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getRandomInt } from "../../Components/Shared/Functions/Functions";
import { recipeContext } from "../../contexts/RecipeContext";
import useRecipes from "../../hooks/useRecipe";
import PageTitle from "../Shared/PageTitle/PageTitle";

const ListMeals = () => {
  const { role, userId } = useSelector((state) => state.user.data);
  // const [recipes, setRecipes] = useRecipes();
  const [recipes, setRecipes] = useState();
  const location = useLocation();
  const { id } = useParams();

  const { mealType } = useContext(recipeContext);

  const dynamicColorButton = (color) => {
    return `py-2 px-4 bg-${color}-600 hover:bg-${color}-700 focus:ring-${color}-500 focus:ring-offset-${color}-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg`;
  };
  useEffect(() => {
    let URL;
    console.log(`/dashboard/recipes/${id}`);

    switch (role) {
      case "admin":
        switch (location.pathname) {
          case "/recipes":
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
          case "/dashboard/recipes":
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
          case `/dashboard/recipes/${id}`:
            URL = `${process.env.REACT_APP_SERVER_URL}/user/${id}/recipe`;
            break;
          default:
            URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
            break;
        }
        break;
      case "general":
        URL = `${process.env.REACT_APP_SERVER_URL}/user/${userId}/recipe`;
        break;
      default:
        URL = `${process.env.REACT_APP_SERVER_URL}/recipe`;
    }
    fetch(URL)
      .then((res) => res.json())
      .then((data) => setRecipes(data));
  }, []);

  const deleteItem = (id) => {
    const proceed = window.confirm("Are you sure?");
    if (proceed) {
      const url = `${process.env.REACT_APP_SERVER_URL}/recipe/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          const remaining = recipes.filter((recipe) => recipe._id !== id);
          setRecipes(remaining);
        });
    }
  };

  const emptyTableData = (
    <>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex items-center">
          <div>
            <p className="text-gray-900 whitespace-no-wrap"></p>
          </div>
        </div>
      </td>
    </>
  );

  return (
    <div>
      <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
        <div className="py-8">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                >
                  {/* Name */}
                </th>
                <th colSpan={2}
                  scope="col"
                  className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm font-bold"
                >
                  Carbon Footprint Kg CO<sub>2</sub> per portion
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                >
                  
                </th>
              </tr>
            </thead>

            <tbody>
              {mealType.map((type) => {
                return (
                  <>
                    <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center">
                          <div>
                            <p className="text-gray-900 whitespace-no-wrap font-bold">
                              {type.value}
                            </p>
                          </div>
                        </div>
                      </td>
                      {emptyTableData}
                      {emptyTableData}
                    </tr>
                    {recipes
                      ?.filter((recipe) => recipe.mealType === type.value)
                      .map((recipe, i) => {
                        let carbonArray = recipe?.Ingredient?.map((item) =>
                          parseFloat(item.carbonEmission)
                        );
                        let sum = carbonArray.reduce(
                          (partialSum, a) => partialSum + a,
                          0
                        );
                        sum = sum/(parseInt(recipe.servings)||1)
                        return (
                          <>
                            <tr key={i}>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-center">
                                  <div>
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {recipe.name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-center">
                                  <div>
                                    <p className="text-gray-900 whitespace-no-wrap font-bold">
                                      {sum.toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <Link
                                  to={`/recipes/edit/${recipe._id}`}
                                  // className={`${dynamicColorButton(
                                  //   "indigo"
                                  // )} mr-3`}
                                  className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-3`}
                                  type="button"
                                >
                                  Edit
                                </Link>

                                <button
                                  href="#!"
                                  type="button"
                                  // className={`${dynamicColorButton("red")}`}
                                  className={`bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded`}
                                  onClick={() => deleteItem(recipe._id)}
                                  value="Add Product"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </>
                );
              })}

              <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex items-center">
                    <div>
                      <p className="text-gray-900 whitespace-no-wrap"></p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex items-center">
                    <div>
                      <p className="text-gray-900 whitespace-no-wrap"></p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <Link
                    to={`/recipes/add`}
                    // className={`${dynamicColorButton("indigo")} mr-3`}
                    className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-3 mr-3`}
                    type="button"
                  >
                    Add New Meal
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListMeals;
