import React from "react";

const Importance = () => {
  const data = [
    {
      heading: "How important is food to carbon?",
      sub: "Should we be concerned?",
      details:
        "A family of 4 eating a meal which typically has 0.5 Kg CO2 will typically emit 750Kg of CO2, equivalent to filling your car six times.",
    },
    {
      heading: "The global situation?",
      sub: "How significant is food sector compared to other sectors?",
      details:
        "According to a study “Decarbonisation roadmap for the European food and drink maufacturing sector” the global sector generates 690 million tonnes of CO2 per year, equivalent to a thirs of total EU emisssions.",
    },
    {
      heading: "How important is eat local?",
      sub: "Is food miles a concern?",
      details: <p>According to <a href="https://ourworldindata.org/food-choice-vs-eating-local">ourworldindata.org</a> contributions to carbon from transport are small. Far more significant is what types of food we eat.</p>
    },
  ];
  return (
    <div className="grid grid-cols-3 gap-16 max-w-[1024px] mx-auto">
      {data.map((item) => {
        return (
          <>
            <div>
              <h1 className="font-semibold mb-2 text-lg">{item.heading}</h1>
              <p className="mb-5 font-[400]">{item.sub}</p>
              <p className="font-[350]">{item.details}</p>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Importance;
