import React from "react";
// import "./Banner.css";
import BannerImg from "../../../assets/bannerImg.jpg";
import BannerBG from './BannerBG.css'


const Banner = () => {
  return (
    <div>
      {/* <div className="banner-head container mx-auto">
        <div className="banner">
          <div className="banner-part">
            <h1>Helping businesses reduce climate impact from food</h1>
            <p>
              Calculate, communicate and report the climate impact of the food
              you serve!
            </p>
            <div className="flex button-group">
              <button className="button">Get Started</button>
              <button className="btn">Book a Demo</button>
            </div>
          </div>
          <div>
            <img
              src="https://uploads-ssl.webflow.com/609a6d7a4139b750702098a1/614361adda45616ce4a69871_menu%20ENG%20with%20leaves%201.png"
              alt=""
            />
          </div>
        </div>
      </div> */}
      <div className="text-white h-[85vh] BannerBG flex items-center justify-center">
        <div className="grid grid-cols-3 mx-auto py-24 max-w-[1024px]">
          <div className="col-span-2">
            <h1 className="text-6xl leading-tight text-bold">
              Understanding the relationship between food and carbon
            </h1>
            <br/><br/>
            <p className="text-xl leading-relaxed">
              Most of us are concerned about climate change and have a some
              knowledge about some of the actions we can take to help reduce our
              impact through carbon emissions reduction.
            </p>
            <br/><br/>
            <p className="text-xl leading-relaxed">
              But what about food? Do we have any idea about how much carbon
              emissions our eating contributes. We might have a broad
              understanding that eating meat is more carbon intensive than
              vegetarian dishes, but by how much.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
