import { createSlice } from "@reduxjs/toolkit";

const emptyUser = {
  name: "guest",
  email: "",
  emailVerified: false,
  photoURL: null,
};

const initialData = JSON.parse(localStorage.getItem("userState")) || { data: null, fetchStatus: '' };

const userSlice = createSlice({
  name: "user",
  // initialState: { data: initialData, fetchStatus: '' },
  initialState: initialData,
  reducers: {
    loginUser: (state, action) => {
      state.data = action.payload;
      localStorage.setItem("userState", JSON.stringify(state));
    },
    logoutUser: (state) => {
      localStorage.removeItem("userState");
      state.data = null;
    },
  },
});

export default userSlice.reducer;

// slice action
export const { loginUser, logoutUser } = userSlice.actions;

// thunk action
export {};
