import React from "react";
import aboutImg from "../../assets/about.jpg";
import Footer from "../../Components/Shared/Footer/Footer";

const About = () => {
  return (
    <>
      <div className="grid grid-cols-2 w-3/5 mx-auto py-16 gap-16 max-w-[1024px]">
        <div className="font-[350]">
          <h1 className="text-3xl font-bold mb-3">How the tool works?</h1>
          <p className="mb-3">Its is quite intuitive:</p>

          <ul className="mb-3 list-disc pl-4">
            <li>Login to the website – create an account if necessary</li>
            <li>
              Choose Menus and start building your recipes into distinct menu
              items
            </li>
          </ul>

          <p className="mb-8">
            The recipe emissions are given for all the ingredients provided.
            However, you should provide the number of servings that can be
            provided from the recipe to determin the emissions per serving
          </p>
          <h1 className="text-3xl font-bold mb-3">
            Where does the data come from?
          </h1>
          <p>
            A major review of lifecycle assessments determining both water and
            carbon footprints was reported in{" "}
            <a href="https://www.nature.com/scientific">
              www.nature.com/scientificdata
            </a>
            . The study “A multilevel carbon and water footprint dataset of food
            commodities” was ublihed in 2021. That study utilised summary data
            from a number of other similar studies for over 3349 determined
            carbon footprints.
          </p>
        </div>
        <div>
          <img src={aboutImg} className="w-full" alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
